import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobTitleService {

  private jobTitle = [
        {id:1 , category: 'Education', title:'Trainer and Assessor'},
        {id:2 , category: 'Education', title: 'Academic Manager',},
        {id:3 , category: 'Education', title:'Trainer and Assessor'},
        {id:4 , category: 'Education', title: 'CEO'},
        {id:5 , category: 'Education', title: 'RTO Manager'},
        {id:6 , category: 'Education', title: 'Student Support Officer'},
        {id:7 , category: 'Education', title: 'Training Coordinator'},
        {id:8 , category: 'Trade', title:'Carpenter'},
        {id:9 , category: 'Trade', title: 'Cabinet Maker'},
        {id:10 , category: 'Trade', title:'Plumber'},
        {id:11 , category: 'Trade', title: 'Electrician'},
        {id:12 , category: 'Trade', title: 'Bricklayer'},
        {id:13 , category: 'Trade', title: 'Wall and Floor Tiler'},
        {id:14 , category: 'Trade', title: 'Roof Tiler'},
        {id:15 , category: 'Trade', title: 'Automotive Technician'},
        {id:16 , category: 'Trade', title: 'Motor Mechanic'},
        {id:17 ,  category: 'Manufacturing', title:'Process Worker'},
        {id:18 ,  category: 'Manufacturing',title: 'Pick Packer'},
        {id:19 ,  category: 'Manufacturing',title:'General factory Hand'},
        {id:20 ,  category: 'Manufacturing',title: 'Boilermaker/Welder'},
        {id:21 ,  category: 'Manufacturing',title: 'Assembler'},
        {id:22 ,  category: 'Manufacturing', title: 'Store Person'},
        {id:23 ,  category: 'Manufacturing',title: 'Fitter'},
        {id:24 ,  category: 'Manufacturing',title: 'Production Coordinator'},
        {id:25 ,  category: 'Manufacturing',title: 'Machine Operator'},
        {id:26 ,  category: 'Manufacturing',title: 'Container Unloader'},
        {id:27 ,  category: 'Manufacturing',title: 'Warehouse Labourer'},
        {id:28 ,  category: 'Engineering',title: 'Civil Engineer'},
        {id:29 ,  category: 'Engineering',title: 'Structural Engineer'},
        {id:30 ,  category: 'Engineering',title: 'Transport Engineer'},
        {id:31 ,  category: 'Engineering',title: 'Mechanical Engineer'},
        {id:32 ,  category: 'Engineering',title: 'Electrical Engineer'},
        {id:33 ,  category: 'Health',title: 'Speech Pathologist'},
        {id:34 ,  category: 'Health',title: 'Occupational Therapist'},
        {id:35 ,  category: 'Health',title: 'Physiotherapist'},
        {id:36 ,  category: 'Health',title: 'Psychotherapist'},
        {id:37 ,  category: 'Health',title: 'Registered Nurse'},
        {id:38 ,  category: 'Health',title: 'Enrolled Nurse'},
        {id:39 ,  category: 'Health',title: 'Assistant in Nursing'},
        {id:40 ,  category: 'Health',title: 'Midwife'},
        {id:41 ,  category: 'Health',title: 'Nurse Educator'},
        {id:42 ,  category: 'Health',title: 'Aged Care Worker'},
        {id:43 ,  category: 'Health',title: 'Disability Support Worker'},
        {id:44 ,  category: 'Health',title: 'Nurse Practitioner'},
        {id:45 ,  category: 'Health',title: 'Welfare Worker'},
        {id:46 ,  category: 'Horticulture',title: 'Gardener'},
        {id:47 ,  category: 'Horticulture',title: 'Landscape Gardener'},
        {id:48 ,  category: 'Horticulture',title: 'Greenkeeper'},
        {id:49 ,  category: 'Horticulture',title: 'Nursery Hand'},
        {id:50 ,  category: 'Horticulture',title: 'Flower Grower'},
        {id:51 ,  category: 'Horticulture',title: 'Market Gardener'},
        {id:52 ,  category: 'Hospitality',title: 'Cafe/Restaurant Manager'},
        {id:53 ,  category: 'Hospitality',title: 'Cook'},
        {id:54 ,  category: 'Hospitality',title: 'Chef'},
        {id:55 ,  category: 'Hospitality',title: 'Sous Chef'},
        {id:56 ,  category: 'Hospitality',title: 'Commis Chef'},
        {id:57 ,  category: 'Hospitality',title: 'Chef De Partie'},
        {id:58 ,  category: 'Hospitality',title: 'Pastry Cook'},
        {id:59 ,  category: 'Hospitality',title: 'Pastry Chef'},
        {id:60 ,  category: 'Hospitality',title: 'Kitchen Hand'},
        {id:61 ,  category: 'IT',title: 'Web developer'},
        {id:62 ,  category: 'IT',title: 'Graphic designer'},
        {id:63 ,  category: 'IT',title: 'SEO Specialist'},
        {id:64 ,  category: 'IT',title: 'ICT Manager'},
        {id:65 ,  category: 'IT',title: 'ICT Project Manager'},
        {id:66 ,  category: 'IT',title: 'Telecommunications Engineer'},
  ]

  private jobCategory = [
    { id: 1, category: "Education"},
    { id: 2, category: "Trade"},
    { id: 3, category: "Manufacturing"},
    { id: 4, category: "Engineering"},
    { id: 5, category: "Health"},
    { id: 6, category: "Horticulture"},
    { id: 7, category: "Hospitality"},
    { id: 8, category: "IT"},
    ];

   private jobType=[
      {id:1 , type:"Full Time"},
      {id:2 , type:"Part Time"},
      {id:3 , type:"Remote"},
      {id:4 , type:"Contract"},
      {id:5 , type:"Casual"},
      // {id:6 , type:"Hourly"},
  ]
  
  private jobSalary=[
    { id: 1, salary: "10,000 - 20,000" },
    { id: 2, salary: "20,000 - 30,000" },
    { id: 3, salary: "30,000 - 40,000" },
    { id: 4, salary: "40,000 - 50,000" },
    { id: 5, salary: "50,000 - 60,000" },
    { id: 6, salary: "60,000 - 70,000" },
    { id: 7, salary: "70,000 - 80,000" },
    { id: 8, salary: "80,000 - 90,000" },
    { id: 9, salary: "90,000 - 100,000" },
    { id: 10, salary: "100,000 - 110,000" },
    { id: 11, salary: "110,000 - 120,000" },
    { id: 12, salary: "120,000 - 130,000" },
    { id: 13, salary: "130,000 - 140,000" },
    { id: 14, salary: "140,000 - 150,000" },
    { id: 15, salary: "150,000 - 160,000" },
    { id: 16, salary: "160,000 - 170,000" },
    { id: 17, salary: "170,000 - 180,000" },
    { id: 18, salary: "180,000 - 190,000" },
    { id: 19, salary: "190,000 - 200,000" },
    { id: 20, salary: "200,000 - 210,000" },
    { id: 21, salary: "210,000 - 220,000" },
    { id: 22, salary: "220,000 - 230,000" },
    { id: 23, salary: "230,000 - 240,000" },
    { id: 24, salary: "240,000 - 250,000" }
  ]

  private hourlySalary=[
    { id: 1, salary: "30 - 40" },
    { id: 2, salary: "40 - 50" },
    { id: 3, salary: "50 - 60" },
    { id: 4, salary: "60 - 70" },
    { id: 5, salary: "70 - 80" },
    { id: 6, salary: "80 - 90" },
    { id: 7, salary: "90 - 100" },
    { id: 8, salary: "100 - 110" },
    { id: 9, salary: "110 - 120" },
    { id: 10, salary: "120 - 130" },
    { id: 11, salary: "130 - 140" },
    { id: 12, salary: "140 - 150" },
    { id: 13, salary: "150 - 160" },
    { id: 14, salary: "160 - 170" },
    { id: 15, salary: "170 - 180" },
    { id: 16, salary: "180 - 190" },
    { id: 17, salary: "190 - 200" },
    // { id: 18, salary: "200 - 210" },
    // { id: 19, salary: "210 - 220" },
    // { id: 20, salary: "220 - 230" },
    // { id: 21, salary: "230 - 240" },
    // { id: 22, salary: "240 - 250" }
  ]

  private expectedMonth=[
    { id: 1, month: "Jan" },
    { id: 2, month: "Feb" },
    { id: 3, month: "Mar" },
    { id: 4, month: "Apr" },
    { id: 5, month: "May" },
    { id: 6, month: "Jun" },
    { id: 7, month: "Jul" },
    { id: 8, month: "Aug" },
    { id: 9, month: "Sep" },
    { id: 10, month: "Oct" },
    { id: 11, month: "Nov" },
    { id: 12, month: "Dec" }
  ]

  private expectedYear = [
    { id: 1, year: "2035" },
    { id: 2, year: "2034" },
    { id: 3, year: "2033" },
    { id: 4, year: "2032" },
    { id: 5, year: "2031" },
    { id: 6, year: "2030" },
    { id: 7, year: "2029" },
    { id: 8, year: "2028" },
    { id: 9, year: "2027" },
    { id: 10, year: "2026" },
    { id: 11, year: "2025" },
    { id: 12, year: "2024" },
    { id: 13, year: "2023" },
    { id: 14, year: "2022" },
    { id: 15, year: "2021" },
    { id: 16, year: "2020" },
    { id: 17, year: "2019" },
    { id: 18, year: "2018" },
    { id: 19, year: "2017" },
    { id: 20, year: "2016" },
    { id: 21, year: "2015" },
    { id: 22, year: "2014" },
    { id: 23, year: "2013" },
    { id: 24, year: "2012" },
    { id: 25, year: "2011" },
    { id: 26, year: "2010" },
    { id: 27, year: "2009" },
    { id: 28, year: "2008" },
    { id: 29, year: "2007" },
    { id: 30, year: "2006" },
    { id: 31, year: "2005" },
    { id: 32, year: "2004" },
    { id: 33, year: "2003" },
    { id: 34, year: "2002" },
    { id: 35, year: "2001" },
    { id: 36, year: "2000" },
    { id: 37, year: "1999" },
    { id: 38, year: "1998" },
    { id: 39, year: "1997" },
    { id: 40, year: "1996" },
    { id: 41, year: "1995" },
    { id: 42, year: "1994" },
    { id: 43, year: "1993" },
    { id: 44, year: "1992" },
    { id: 45, year: "1991" },
    { id: 46, year: "1990" },
    { id: 47, year: "1989" },
    { id: 48, year: "1988" },
    { id: 49, year: "1987" },
    { id: 50, year: "1986" },
    { id: 51, year: "1985" },
    { id: 52, year: "1984" },
    { id: 53, year: "1983" },
    { id: 54, year: "1982" },
    { id: 55, year: "1981" },
    { id: 56, year: "1980" },
    { id: 57, year: "1979" },
    { id: 58, year: "1978" },
    { id: 59, year: "1977" },
    { id: 60, year: "1976" },
    { id: 61, year: "1975" },
    { id: 62, year: "1974" },
    { id: 63, year: "1973" },
    { id: 64, year: "1972" },
    { id: 65, year: "1971" },
    { id: 66, year: "1970" },
    { id: 67, year: "1969" },
    { id: 68, year: "1968" },
    { id: 69, year: "1967" },
    { id: 70, year: "1966" },
    { id: 71, year: "1965" },
    { id: 72, year: "1964" },
    { id: 73, year: "1963" },
    { id: 74, year: "1962" },
    { id: 75, year: "1961" },
    { id: 76, year: "1960" },
    { id: 77, year: "1959" },
    { id: 78, year: "1958" },
    { id: 79, year: "1957" },
    { id: 80, year: "1956" },
    { id: 81, year: "1955" },
    { id: 82, year: "1954" },
    { id: 83, year: "1953" },
    { id: 84, year: "1952" },
    { id: 85, year: "1951" },
    { id: 86, year: "1950" },
    { id: 87, year: "1949" },
    { id: 88, year: "1948" },
    { id: 89, year: "1947" },
    { id: 90, year: "1946" },
    { id: 91, year: "1945" },
    { id: 92, year: "1944" },
    { id: 93, year: "1943" },
    { id: 94, year: "1942" },
    { id: 95, year: "1941" },
    { id: 96, year: "1940" },
    { id: 97, year: "1939" },
    { id: 98, year: "1938" },
    { id: 99, year: "1937" },
    { id: 100, year: "1936" },
    { id: 101, year: "1935" },
    { id: 102, year: "1934" },
    { id: 103, year: "1933" },
    { id: 104, year: "1932" },
    { id: 105, year: "1931" },
    { id: 106, year: "1930" },
    { id: 107, year: "1929" },
    { id: 108, year: "1928" },
    { id: 109, year: "1927" },
    { id: 110, year: "1926" },
    { id: 111, year: "1925" },
    { id: 112, year: "1924" }
];

  
  private finishedYear = [
    { id: 1, year: "2025" },
    { id: 2, year: "2024" },
    { id: 3, year: "2023" },
    { id: 4, year: "2022" },
    { id: 5, year: "2021" },
    { id: 6, year: "2020" },
    { id: 7, year: "2019" },
    { id: 8, year: "2018" },
    { id: 9, year: "2017" },
    { id: 10, year: "2016" },
    { id: 11, year: "2015" },
    { id: 12, year: "2014" },
    { id: 13, year: "2013" },
    { id: 14, year: "2012" },
    { id: 15, year: "2011" },
    { id: 16, year: "2010" },
    { id: 17, year: "2009" },
    { id: 18, year: "2008" },
    { id: 19, year: "2007" },
    { id: 20, year: "2006" },
    { id: 21, year: "2005" },
    { id: 22, year: "2004" },
    { id: 23, year: "2003" },
    { id: 24, year: "2002" },
    { id: 25, year: "2001" },
    { id: 26, year: "2000" },
    { id: 27, year: "1999" },
    { id: 28, year: "1998" },
    { id: 29, year: "1997" },
    { id: 30, year: "1996" },
    { id: 31, year: "1995" },
    { id: 32, year: "1994" },
    { id: 33, year: "1993" },
    { id: 34, year: "1992" },
    { id: 35, year: "1991" },
    { id: 36, year: "1990" },
    { id: 37, year: "1989" },
    { id: 38, year: "1988" },
    { id: 39, year: "1987" },
    { id: 40, year: "1986" },
    { id: 41, year: "1985" },
    { id: 42, year: "1984" },
    { id: 43, year: "1983" },
    { id: 44, year: "1982" },
    { id: 45, year: "1981" },
    { id: 46, year: "1980" },
    { id: 47, year: "1979" },
    { id: 48, year: "1978" },
    { id: 49, year: "1977" },
    { id: 50, year: "1976" },
    { id: 51, year: "1975" },
    { id: 52, year: "1974" },
    { id: 53, year: "1973" },
    { id: 54, year: "1972" },
    { id: 55, year: "1971" },
    { id: 56, year: "1970" },
    { id: 57, year: "1969" },
    { id: 58, year: "1968" },
    { id: 59, year: "1967" },
    { id: 60, year: "1966" },
    { id: 61, year: "1965" },
    { id: 62, year: "1964" },
    { id: 63, year: "1963" },
    { id: 64, year: "1962" },
    { id: 65, year: "1961" },
    { id: 66, year: "1960" },
    { id: 67, year: "1959" },
    { id: 68, year: "1958" },
    { id: 69, year: "1957" },
    { id: 70, year: "1956" },
    { id: 71, year: "1955" },
    { id: 72, year: "1954" },
    { id: 73, year: "1953" },
    { id: 74, year: "1952" },
    { id: 75, year: "1951" },
    { id: 76, year: "1950" },
    { id: 77, year: "1949" },
    { id: 78, year: "1948" },
    { id: 79, year: "1947" },
    { id: 80, year: "1946" },
    { id: 81, year: "1945" },
    { id: 82, year: "1944" },
    { id: 83, year: "1943" },
    { id: 84, year: "1942" },
    { id: 85, year: "1941" },
    { id: 86, year: "1940" },
    { id: 87, year: "1939" },
    { id: 88, year: "1938" },
    { id: 89, year: "1937" },
    { id: 90, year: "1936" },
    { id: 91, year: "1935" },
    { id: 92, year: "1934" },
    { id: 93, year: "1933" },
    { id: 94, year: "1932" },
    { id: 95, year: "1931" },
    { id: 96, year: "1930" },
    { id: 97, year: "1929" },
    { id: 98, year: "1928" },
    { id: 99, year: "1927" },
    { id: 100, year: "1926" },
    { id: 101, year: "1925" },
    { id: 102, year: "1924" }
];


private jobStatus = [
  {id:1 , status:"applied"},
  { id: 2, status: "interview" },
  { id: 3, status: "successful" },
  { id: 4, status: "rejected"},
]

private elementType = [
  { id: 1, type: "text" },       
  { id: 2, type: "textarea" },   
  { id: 3, type: "number" },     
  { id: 4, type: "select" },     
  { id: 5, type: "checkbox"}, 
  { id: 7, type: "email" },
]

private additionalCertificate = [
  { id: 1, certificate: "Blue Card" },
  { id: 2, certificate: "Contractor Card" },
  { id: 3, certificate: "RSL" },
  { id: 4, certificate: "RSA" }       
]

private industryCurrency = [
  { id: 1, industryCurrency: "Reference Letter" },
  { id: 2, industryCurrency: "Employment Agreement" },
  { id: 3, industryCurrency: "Recent Payslips" }
]

private industryCurrencyVerification = [
  { id: 1, certificate: "Industry Currency Verification" },
  { id: 2, certificate: "Magnus Verification Letter" }
]

private referenceCheck = [
  { id: 1, certificate: "Magnus Reference Check Report" }
]

private pdLogAndPlan = [
  { id: 1, certificate: "PD log" },
  { id: 2, certificate: "PD plan" }
]

private professionalDevelopment = [
  { id: 1, certificate: "Industry PD" },
  { id: 2, certificate: "VET PD" }
]

private usiReport = [
  { id: 1, certificate: "USI Report" }
]

private resume = [
  { id: 1, certificate: "Updated Resume" }
]



  constructor() { }
  
  //job category
  getJobCategory(){
    return this.jobCategory
  }

  //job title's
  getJobTitle(){
    return this.jobTitle;
  }

  //job type
  getJobType(){
    return this.jobType;
  }

  //job salary
  getJobSalary(){
    return this.jobSalary;
  }

  //job hourly salary
  getHourlySalary(){
    return this.hourlySalary;
  }

  //expected finish month
  getExpectedMonth(){
    return this.expectedMonth;
  }

  //expected finish year
  getExpectedYear(){
    return this.expectedYear;
  }

  //finished year
  getFinishedYear(){
    return this.finishedYear;
  }

  //status
  getStatus(){
    return this.jobStatus
  }

  //get element job type
  getElementJobType(){
    return this.elementType;
  }

  //get additional certificate
  getAdditionalCertificate(){
    return this.additionalCertificate
  }

  //get industry currency
  getIndustryCurrency(){
    return this.industryCurrency
  }

  //get industry currency verification
  getIndustryCurrencyVerification(){
    return this.industryCurrencyVerification
  }

  //get reference check
  getReferenceCheck(){
    return this.referenceCheck
  }

  //get PD log and Plan
  getPDLogAndPlan(){
    return this.pdLogAndPlan;
  }
 
  //get Professional Development
  getProfessionalDevelopment(){
    return this.professionalDevelopment;
  }

  //get usi report
  getUSIReport(){
    return this.usiReport;
  }

  //get resume
  getResume(){
    return this.resume;
  }
}
