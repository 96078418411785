<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7">
                            <img src="../../.././../assets/img/home-1/about6.jpg" alt="Portal" />
                        </div>

                        <div class="col-lg-5">
                            <img src="../../.././../assets/img/home-1/about7.jpg" alt="Portal" />
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right portal-right-two">
                    <h2>Trusted Recruitment Partner</h2>
                    <p>
                        Founded in 2016, our company has grown to become a
                        leading name in the Australian recruitment industry.
                        From our humble beginnings, we have built a reputation
                        for excellence,dedication and a deep understanding of
                        the sectors we serve across all of Australia.Our success
                        story is rooted in our commitment to connecting the
                        right talent with the right opportunities, ensuring that
                        businesses thrive, and professionals reach their full
                        potential.We started our journey by recruiting for
                        Education and over the years, we have expanded our reach
                        across various sectors such as Healthcare, IT,
                        Engineering, Hospitality, Manufacturing, Trade and
                        Horticulture.
                    </p>

                    <!-- <div class="portal-counter-area">
                        <div class="row">
                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">+300K</span></h3>
                                    <p>Talent Database</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">+120K</span></h3>
                                    <p>Jobs Placements</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">+100K</span></h3>
                                    <p>Professional Development Programs</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-4 offset-lg-2">
                                <div class="counter-item">
                                    <h3><span class="counter">+10K</span></h3>
                                    <p>Satisfied Active Clients</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">50</span></h3>
                                    <p>Staffs</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">+300K</span></h3>
                    <p>Talent Database</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3><span class="counter">+120K</span></h3>
                    <p>Jobs Placements</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3><span class="counter">+10K</span></h3>
                    <p>Satisfied Active Clients</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i
                        class="flaticon-businessman-paper-of-the-application-for-a-job"
                    ></i>
                    <h3><span class="counter">+100K</span></h3>
                    <p>Professional Development Programs</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popular-area pt-100 pb-170">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <img src="../../.././../assets/img/home-1/about8.jpg" alt="Popular" />
                        </div>

                        <div class="col-lg-5">
                            <div class="practice-inner">
                                <img
                                    height="310px"
                                    src="../../.././../assets/img/home-1/about9.jpg"
                                    alt="Popular"
                                />
                                <img
                                    height="210px"
                                    src="../../.././../assets/img/home-1/about5.jpg"
                                    alt="Popular"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>Why We are Most Popular</h2>
                    </div>
                    <p>
                        We have successfully extended our services to Gulf
                        countries and Singapore, and we have been able to meet
                        the unique needs of our clients there with great
                        success. This has marked as a significant milestone in
                        our journey as we serve clients and candidates on a
                        global scale. With our commitment to excellence and a
                        vast database, we are fully equipped to meet the diverse
                        needs of industries worldwide.
                    </p>

                    <div class="row popular-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li>
                                    <i class="fa fa-user"></i><span>CLIENT - CENTRIC APPROACH</span> 

                                </li>
                                <li>
                                    <i class="flaticon-no-money"></i> EXPERTISE
                                </li>
                            </ul>
     
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li>
                                    <i class="flaticon-enterprise"></i>INTEGRITY
                                </li>
                                <li>
                                    <i class="flaticon-employee"></i>
                                    QUALITY
                                </li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6 mt-3">
                            <ul>
                                <li>
                                    <i class="flaticon-approved"></i><span>INNOVATION</span> 

                                </li>
                            
                            </ul>
                        </div>
                        <div class="col-sm-6 col-lg-6 mt-3">
                            <ul>
                                <li>
                                    <i class="fa fa-check"></i><span>SUSTAINABILITY</span> 

                                </li>
                               
                            </ul>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
